/* You can add global styles to this file, and also import other style files */
@use '@connatix/ui-theme/connatix-theme/colors/colors';
// COMPONENTS
@import './components/config';

ckit-cnx-header {
    .mdc-button {
        margin-top: 0;
    }
}

ui-kit-input-search {
    mat-form-field.cnx-search {
        padding-top: 0;
    }
}

ui-kit-card {
    [card-header-prefix] mat-slide-toggle {
        padding-top: 0;
    }
}

.select-overlay .select-overlay-actions button {
    margin-top: 0;
}

.red-text {
    color: colors.$volcano-normal;
}

